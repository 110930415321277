.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.notFound h1 {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.notFound p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.homeLink {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.homeLink:hover {
  background-color: var(--secondary-color);
}
