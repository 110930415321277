body {
  background: linear-gradient(
    180deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.App {
  text-align: center;
}
